html {
  --section-background-color: linear-gradient(
    to bottom left,
    rgba(17, 16, 16, 0.582),
    rgba(12, 8, 24, 0.904)
  );

  --image-gradient: linear-gradient(
    to bottom left,
    rgba(17, 16, 16, 0.678),
    rgba(12, 10, 22, 0.863)
  );

  --imp-text-color: #008000;
}

/* --------- */
/*  Preloader */
/* --------- */
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: #0c0513;
  background-image: url(./Assets/pre.svg);
  background-repeat: no-repeat;
  background-position: center;
}

#preloader-none {
  opacity: 0;
}

#no-scroll {
  overflow: hidden;
  height: 100vh;
}

/* --------- */
/*Scrollbar   */
/* --------- */

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background:black;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(99, 175, 103) ;
  border-radius: 12px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(99, 175, 103) ;
  border-radius: 12px;
}

/* --------- */
/* Navbar Section  */
/* --------- */
.sticky {
  background-color: #1b1a2ea9 !important;
  transition: all 0.3s ease-out 0s !important;
  box-shadow: 0px 10px 10px 0px rgba(9, 5, 29, 0.171) !important;
  backdrop-filter: blur(15px) !important;
}

.navbar {
  position: fixed !important;
  transition: all 0.3s ease-out 0s !important;
  padding: 0.3rem 2rem !important;
  font-size: 1.2rem !important;
}

.navbar-toggler {
  position: relative !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.navbar-toggler span {
  display: block !important;
  background-color: #008000 !important;
  height: 4px !important;
  width: 27px !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  transform: rotate(0deg) !important;
  left: 0 !important;
  opacity: 1 !important;
}

.navbar-toggler:focus,
.navbar-toggler:active {
  outline: 0 !important;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
  transition: transform 0.35s ease-in-out !important;
  transition: transform 0.35s ease-in-out !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(135deg) !important;
  opacity: 0.9 !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
  height: 12px !important;
  visibility: hidden !important;
  background-color: transparent !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(-135deg) !important;
  opacity: 0.9 !important;
}

@media (max-width: 800px) {
  .navbar {
    padding: 1rem 2rem !important;
    font-size: 1.4rem !important;
    background-color: #181a27 !important;
  }
  .navbar-nav .nav-item a::after {
    display: none !important;
  }
}

.navbar-nav .nav-link {
  color: white !important;
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.nav-link {
  padding: 0.8rem 1rem !important;
}

@media (max-width: 800px) {
  .nav-link {
    padding: 0.7rem 1rem !important;
  }
}

.navbar-nav .nav-item {
  position: relative;
  margin-left: 20px;
}

.navbar-nav .nav-item a {
  font-weight: 400;
  transition: all 0.3s ease-out 0s;
  position: relative;
  z-index: 1;
}

.navbar-nav .nav-item a::after {
  content: "";
  position: relative;
  display: block;
  height: 5px;
  width: 0;
  border-radius: 16px;
  background: #008000;
  bottom: 1px;
  left: 0;
  z-index: -1;
  transition: all 0.3s ease-out 0s;
}

.navbar-nav .nav-item a:hover::after {
  width: 100%;
}

/* --------- */
/* Home section */
/* --------- */
.wave {
  animation-name: wave-animation; /* Refers to the name of your @keyframes element below */
  animation-duration: 2.1s; /* Change to speed up or slow down */
  animation-iteration-count: infinite; /* Never stop waving :) */
  transform-origin: 70% 70%; /* Pivot around the bottom-left palm */
  display: inline-block;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  } /* The following five values can be played with to make the waving more or less extreme */
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  } /* Reset for the last half to pause */
  100% {
    transform: rotate(0deg);
  }
}
#tsparticles {
  position: fixed !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  width: 100%;
  height: 100%;
}

.img-fluid {
  margin-right: 20px;
  height: 350px;
  width: 350px;
  max-width: 350px;
  border-radius: 200px;
}

@media (max-width : 800px) {
  .img-fluid {
    margin-top: 50px;
    align-self: center;
    justify-self: center;
    margin-top: 50px;
    align-self: center;
    justify-self: center;
    max-width: 80%;
  }
  .img {
    max-width: 80% !important;
  }
  .magic-img {
    max-width: 90% !important;
    max-height: 300%;
    object-fit: cover;
  }
}

.home-intro{
  padding-left: 5% !important;
}

.heading {
  font-size: 2.4em !important;
  padding-left: 50px !important;
}

.heading-name {
  margin-top: 50px;
  font-size: 2.5em !important;
  color: white;
  /* padding-left: 45px !important; */
}

.main-name {
  color: #008000;
}

.main-white {
  color: white;
}

.Typewriter__wrapper {
  font-size: 2.2em !important;
  color: #008000 !important;
  font-weight: 600 !important;
}
.Typewriter__cursor {
  font-size: 2.4em !important;
  color: #008000 !important;
}

@media (max-width: 800px) {
  .Typewriter__wrapper {
    font-size: 1.4em !important;
    font-weight: 500 !important;
    position: absolute !important;
  }
  .Typewriter__cursor {
    display: none !important;
  }
}

.myAvtar {
  justify-content: center !important;
  padding-top: 9em !important;
}

@media (max-width: 800px) {
  .myAvtar {
    padding-top: 2em !important;
    padding-bottom: 2em !important;
  }
}

.home-about-section {
  position: relative;
  padding-bottom: 70px !important;
  padding-top: 70px !important;
}

.home-about-description {
  color: white !important;
  padding-top: 100px !important;
  padding-bottom: 20px !important;
  text-align: center;
}

.home-about-body {
  padding-top: 0px;
  font-size: 1.2em !important;
  text-align: center;
  color: white;
}

.home-header {
  padding-top: 40px !important;
}

.home-ctn1{
  /* grid-template-columns: 1fr 1fr; */
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

@media (max-width: 800px) {
  .home-ctn1 {
    display: grid !important;
    grid-template-columns: 1fr;
    align-items: center;
    justify-content: center;
  }
}


.home-social-icons {
  position: relative !important;
  display: inline-block !important;
  width: 40px !important;
  height: 40px !important;
  text-align: center !important;
  font-size: 1.2em !important;
  line-height: 2em !important;
  background: rgba(255, 255, 255, 0.972) !important;
  border-radius: 50% !important;
  transition: 0.5s !important;
}

.home-social-icons::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #008000;
  transition: 0.5s;
  transform: scale(0.9);
  z-index: -1;
}

.home-social-icons:hover::before {
  transform: scale(1.1);
  box-shadow: 0 0 15px #008000;
}

.home-social-icons:hover {
  color: #008000;
  box-shadow: 0 0 5px #008000;
  text-shadow: 0 0 2px #008000;
}

.social-icons {
  display: inline-block !important;
  padding-right: 15px;
  padding-left: 15px;
}

.icon-colour {
  color: #008000 !important;
}

/* --------- */
/* Footer */
/* --------- */
.footer {
  background-color: rgb(10, 4, 22);
  bottom: 0 !important;
  padding-top: 10px !important;
  padding-bottom: 8px !important ;
}
.footer-copywright {
  text-align: center !important;
}

.footer-body {
  text-align: center !important;
}

@media (max-width: 800px) {
  .footer-copywright {
    text-align: center !important;
  }

  .footer-body {
    text-align: center !important;
  }
}

.footer h3 {
  font-size: 1em;
  color: white !important;
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
}
.footer-icons {
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
  padding: 0 !important;
}

/* ---------------- */
/* text  */
/* ---------------- */
.link-text {
  margin-top: 50px;
  font-size: 1.2em !important;
  text-align: left;
  text-decoration: underline white;
  color: white;
}

.link-text:hover {
  padding-top: 0px !important;
  margin-top: 50px;
  font-size: 1.2em !important;
  text-align: left;
  text-decoration: underline white;
  background-color: #008000;
  color: white;
  cursor: pointer;
  transform: scale(1.02) !important;
  overflow: hidden !important;
  box-shadow: 0 4px 4px 5px rgba(27, 163, 136, 0.459)  !important;
  border-radius: 10px;
}


/* --------- */
/* Projects */
/* --------- */

.project-card {
  align-self:center;
  width: 65%;
  justify-self: center;
  border-color: #fff;
  padding: 20px 0 20px 0 ;
  border: #fff 2px solid;
  border-radius: 10px;
  margin: 20px 0 20px 0;
  cursor: pointer;
}

/* .magic-card {
  align-self:center;
  width: 90%;
  justify-self: center;
  border-color: #fff;
  padding: 20px 0 20px 0 ;
  margin: 20px 0 20px 0;
} */

@media screen and (max-width: 750px) {
  iframe {
      max-width: 100% !important;
      width: auto !important;
      height: auto !important;
  }
}


.project-card:hover {
  transform: scale(1.02) !important;
  overflow: hidden !important;
  box-shadow: 0 4px 4px 5px rgba(27, 163, 136, 0.459)  !important;
}

.project-container {
  padding: 25px 5% 50px 5%  ;
  display: grid;
  height: auto !important;
  grid-template-columns: 1fr 1fr;
} 

.card-title {
  font-size: 20px;
  color: #fff;
}

.project-card-view {
  width: 45%;
  border-width: 1px;
  border-color: aliceblue;
  color: white !important;
  border-radius: 5px;
  background-color: transparent !important;
  opacity: 0.9 !important;
  transition: all 0.5s ease 0s !important;
  height: 100% !important;
  padding: 100px;
  padding-top: 50px !important;
  padding-bottom: 50px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
  cursor: pointer; 
  align-self: center; 
  justify-self: center; 
}

@media screen and (max-width: 800px) {
  .project-container  {
    grid-template-columns: 1fr !important;
    padding: 25px 0 50px 0 !important;
  }
}

.img {
  max-height: 200px;
  max-width: 80%;
  border-radius: 10px;
  margin: 10px;
}

.magic-img {
  height: 300px;
  width: 90%;
  border-radius: 10px;
  margin: 20px;
  object-fit: cover;
}


/* ---------------- */
/* Portfolio  */
/* ---------------- */

@media screen and (max-width: 800px) {
  .portfolio-card  {
    grid-template-columns: 1fr !important;
    padding: 50px 10% 50px 10% !important;
  }
  .portfolio-img {
    max-width: 250px;
    margin: 10px 0 10px 0;
    max-width: 120%;
  }
  .portfolio {
    padding: 80px 3% 80px 3% !important;
  }
}

.portfolio-title {
  font-weight:bold ;
}

.portfolio-title:hover {
  cursor: pointer;
  text-decoration: underline;
}

.portfolio {
  padding: 80px;
}

.portfolio-description {
  color: rgb(58, 55, 55);
  font-size: 20px;
}

.portfolio-card {
  padding: 50px 10% 50px 10%  ;
  display: grid;
  height: auto !important;
  grid-template-columns: 1fr 1fr;
}

.portfolio-text-container {
  justify-content: center;
  align-items: center;
  text-align: left;
}

.portfolio-img {
  max-height: 250px;
  margin: 10px 0 10px 0;
  max-width: 120%;
}

.portfolio-img:hover {
  cursor: pointer;
  transform: scale(1.02) !important;
}

.portfolio-img-container {
  align-items: center;
  justify-content: center;
}